<template>
  <section class="story-examples">
    <h2 class="mb-3">Stories from the West</h2>
    <p>Tour the western US to learn how wildfires are impacting lives and how people and systems are adapting to this new normal.</p>
    <!-- <v-btn color="accent" depressed class="btn-extra-wide ml-0 mt-4 mb-4" :to="{ name: 'storyMap' }">Explore story map <v-icon right>place</v-icon></v-btn> -->
    <div class="story-examples__container mt-4">
      <div class="story-examples__videos">
        <router-link :to="{ name: 'storyMap', params: { path: story.path } }" class="story-examples__video"
          v-for="story in stories" :key="story.id">
          <v-img :src="`${host}${story.field_image.uri.url}`"></v-img>

          <div class="story-example__video-hover">
            <p class="story-example__video-hover-title">{{ story.title }}</p>
            <span class="story-example__video-hover-caption">{{story.body.summary}}</span>
            <v-btn
              class="story-examples__video-icon"
              icon
              outline
              color="white white--text"
              :to="{ name: 'storyMap', params: { path: story.path } }"
              :aria-label="story.title"
            >
              <v-icon>arrow_forward</v-icon>
            </v-btn>
          </div>

        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
  import {
    actions as contentActions,
    getters as contentGetters
  } from '@/mixins/connectors/content'
  import {
    allStories
  } from '@/api/utils/queries'
  
  const {
    VUE_APP_DRUPAL_API
  } = process.env

  export default {
    name: 'story-examples',

    mixins: [contentActions, contentGetters],

    created() {
      this.fetchContentTypeAll({ type: 'story', filter: allStories })
    },

    data() {
      return {
        // stories
        host: VUE_APP_DRUPAL_API
      }
    },

    computed: {
      stories() {
        return this.getContentTypeByType('story')
      }
    }
  }
</script>

<style lang="stylus">
  .story-examples
    .story-examples__container
      margin-right: calc(-5% - 18px)
      margin-left: calc(-5% - 18px)
    .story-examples__videos
      width: 100%
      -webkit-overflow-scrolling: touch
      overflow-y: hidden
      overflow-x: auto
      -ms-flex-wrap: nowrap
      flex-wrap: nowrap
      white-space: nowrap
      scroll-snap-type: x mandatory
    .story-examples__video
      width 80%
      display inline-flex
      margin 0 7px
      position: relative
      scroll-snap-align center 

      .youtube-video
        width: 100%

      .story-example__video-hover
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        display: flex
        flex-direction: column
        justify-content: flex-end
        color: #fff
        background-color: rgba($color-dark-grey, 0.7)
        padding: 15px
        cursor: pointer

      .story-examples__video-icon
        position: absolute
        right: 10px
        bottom: 10px
      
      .story-example__video-hover-title
        font-size: 16px
        display: block
        padding: 0
        margin: 0
        font-weight: bold
      .story-example__video-hover-caption
        white-space: normal
        max-width: 60%

    @media(min-width:$sm-breakpoint)
      .story-examples__container
        margin-left: calc(-100vw / 2 + 568px / 2);
        margin-right: calc(-100vw / 2 + 568px / 2);
      .story-examples__video
        width: 60%
    @media(min-width:$md-breakpoint)
      .story-examples__container
        margin-left: calc(-100vw / 2 + 650px / 2);
        margin-right: calc(-100vw / 2 + 650px / 2);
      .story-examples__video
        width: 40%
  .application.theme--light .story-examples__container
    .v-btn--active:before, .v-btn:hover:before, .v-btn:focus:before
      background-color: transparent !important
</style>


