<template>
  <section class="cbo-section">
    <h2 class="mb-3">Support our forests</h2>
    <p>Learn about forests near and far by connecting with partner organizations who work hard to keep them healthy. Your donations support their efforts so forests can be enjoyed for generations to come.</p>
    <v-btn color="accent" depressed class="btn-extra-wide ml-0 mb-4" :to="{ name: 'connect' }">Connect with Organizations</v-btn>
  </section>
</template>

<script>
export default {
  name: 'cbo-section'
}
</script>
