<template>
  <div class="home">
    <!-- only appears during production build -->
    <h1 v-if="isPrerendering">How have wildfires impacted you?</h1>
    <featured-video></featured-video>

    <!-- TODO: look into better swipe transitions -->
    <!-- <v-window v-model="activeVideo">
      <v-window-item
        lazy
        v-for="(video, index) in featuredVideos"
        :key="video.id">
        <featured-video
          :playPause="activeVideo === index"
          @ended="nextVideo"
          :video-id="video.videoId"
          :header-text="video.text"></featured-video>
      </v-window-item>
    </v-window> -->

    <!-- <v-btn @click="nextVideo" color="accent">Next Story</v-btn> -->
    
    <view-wrapper no-header>
      <add-this
        v-if="!isPrerendering"
        pub-id="ra-5c4e5c4cb2326495"
        :data-url="getUrl"
        data-title="The West is Burning: How have wildfires impacted you?"
        :data-media="getAddThisMedia">
      </add-this>
      
      <v-layout>
        <v-flex sm6>
          <v-btn
            depressed
            round
            color="accent"
            block
            :to="{ name: 'watch' }">
            Watch Film
          </v-btn>
        </v-flex>
        <v-flex sm6>
          <trailer-dialog v-on:playing-trailer="handlePlaying"></trailer-dialog>
        </v-flex>
      </v-layout>

      <section class="home__ecological-disaster">
        <h2 class="mb-3">The way forward in the era of Megafire</h2>

        <p>
          <strong>THE WEST IS BURNING</strong> raises awareness about the conditions of forests in the western U.S. Told through a <strong>full-feature documentary</strong>, we examine the history of forest management and litigation that led to the current conditions which are causing catastrophic fire nearly year-round. The film explores the urgent need to act now, and the potential to generate positive change in our forests, watersheds, and communities, both rural and urban.
        </p>
        
        <p>
          An app accompanies the documentary as a way for <strong>you to create short films</strong> with a narrow focus. Discover these short films on our <strong>story map</strong>.
        </p>
        
        <p>
          Find <strong>community hosted screenings</strong> followed by engaging dialogue. Or host your own screening! We’ll provide a guide to help you facilitate it.
        </p>
        
        <p>
          You have an important role in this conversation and we want you to be informed and involved.
        </p>

        <!-- <v-btn color="accent" depressed class="ma-0 mt-4 btn-extra-wide" :to="{ name: 'join' }">Join The Conversation</v-btn> -->
      </section>
      <section class="home__why-important">
        <h2 class="mb-3">Why is this important</h2>
        <v-container grid-list-md class="mt-5">
          <v-layout row wrap justify-space-between>
            <v-flex xs12 md4>
              <icon-info :icon="$vuetify.icons.wib_hot_fires" icon-title="Hot Dry Summers">
                As summers get hotter, longer, and drier, dense woody fuels pile up and more of our forests will burn.
              </icon-info>
            </v-flex>
            <v-flex xs12 md4>
              <icon-info :icon="$vuetify.icons.wib_mega_fires" icon-title="Megafires">
                Megafires are threatening our communities, affecting our health, water supplies, and ecosystem in ways we are just beginning to understand.
              </icon-info>
            </v-flex>
            <v-flex xs12 md4>
              <icon-info class="last-child" :icon="$vuetify.icons.wib_cost" icon-title="Recovery Costs">
                Recovery costs are nearing trillions of dollars as these megafires engulf entire communities.
              </icon-info>
            </v-flex>
          </v-layout>
        </v-container>
      </section> 
      <section>
        <testimonial quote>
          We need to embrace a new era of forest stewardship using all of our management tools including thinning, prescribed fire and managed wildfire.

          <template #name>
            Melanie Parker
          </template>
          <template #extra>
            Deputy Director, Sonoma County Regional Parks
          </template>
        </testimonial>
      </section>
      <story-examples v-if="!isPrerendering"></story-examples>
      
      <make-films-section />
      <cbo-section />
    </view-wrapper>
  </div>
</template>

<script>
// @ is an alias to /src
import AddThis from '@/components/AddThis/AddThis'
import IconInfo from '@/components/IconInfo/IconInfo'
import Testimonial from '@/components/Testimonial/Testimonial'
import FeaturedVideo from '@/components/FeaturedVideo/FeaturedVideo'
import MakeFilmsSection from '@/components/MakeFilmsSection/MakeFilmsSection'
import CboSection from '@/components/CboSection/CboSection'
import StoryExamples from '@/components/StoryExamples/StoryExamples'
import TrailerDialog from '@/components/_dialogs/TrailerDialog/TrailerDialog'
import { mapActions } from 'vuex'

export default {
  name: 'home',

  components: {
    AddThis,
    FeaturedVideo,
    IconInfo,
    Testimonial,
    MakeFilmsSection,
    CboSection,
    StoryExamples,
    TrailerDialog
  },

  data() {
    return {
      isPrerendering: window.__PRERENDER_INJECTED, // TODO: this might not be needed
      activeVideo: 0,
      featuredVideos: [
        {
          id: 1,
          videoId: 'wq76DxDfXq0',
          text: 'How have wildfires impacted you?'
        },
        {
          id: 2,
          videoId: 'wq76DxDfXq0',
          text: 'The devastation is spreading...'
        },
        {
          id: 3,
          videoId: 'wq76DxDfXq0',
          text: 'Wondering how you can help?'
        }
      ]
    }
  },

  methods: {
    ...mapActions(['generalEvent']),
    handlePlaying() {
      this.generalEvent({
        category: 'video',
        action: 'playing',
        label: 'trailer'
      })
    }
  },

  computed: {
    getUrl() {
      return (window) ? window.location.href : ''
    },
    getAddThisMedia() {
      return `${this.getUrl}img/mainPoster.jpg`
    }
  }
};
</script>

<style lang="stylus">
  .home
    .view-wrapper
      padding-top: 50px

    .home__ecological-disaster
      margin-top 50px

    .addthis_inline_share_toolbox
      position: absolute
      right: 0
      top: 10px
    
    .home__why-important
      .icon-info p
        font-size: 14px
      
      .v-icon
        width 57px
        height 57px
      
      .container.grid-list-md
        padding 0
      
      .container.grid-list-md .layout .flex
        padding 0
      
      .container.grid-list-md .layout:only-child
        margin 0
 
    @media(min-width: $sm-breakpoint)
      .addthis_inline_share_toolbox
        top: -40px

    @media(min-width: $md-breakpoint)
      .view-wrapper
        margin-top: 20px
        padding-top: 0

      .home__ecological-disaster
        margin-top 100px

      .home__why-important
        .container.grid-list-md
          margin-left -85px
          margin-right -85px
          width 850px
        
        .flex.md4
          max-width 260px
    
    @media(min-width: $lg-breakpoint)
      .home__why-important
        .container.grid-list-md
          margin-left -158px
          margin-right -158px
          width 1000px
        
        .flex.md4
          max-width 300px
</style>
