<template>
  <v-dialog
    v-model="dialog"
    max-width="1000"
    class="trailer-dialog"
    content-class="trailer-dialog__content">

    <v-btn  
      slot="activator"
      depressed
      round
      color="accent"
      block
      @click="player.playVideo()"
      :disabled="videoLoading"
      :loading="videoLoading">
      Watch Trailer
    </v-btn>

    <youtube-video
      :video-id="TRAILER_YOUTUBE_ID"
      :controls="true"
      :plays-inline="true"
      :toggle-play="dialog"
      @playing="$emit('playing-trailer')"
      @ready="handleReady"
      @ended="dialog = false"
      :synced-player.sync="player">
    </youtube-video>
  </v-dialog>
</template>

<script>
import { TRAILER_YOUTUBE_ID } from '@/utils/constants'

export default {
  name: 'trailer-dialog',

  data() {
    return {
      videoLoading: true,
      dialog: false,
      player: {},
      TRAILER_YOUTUBE_ID
    }
  },

  methods: {
    handleReady() {
      this.videoLoading = false
    }
  }
}
</script>

<style lang="stylus">
  .trailer-dialog
    display: block !important

    .v-dialog__activator
      margin: 0 auto

  .trailer-dialog__content
    margin: 0 !important

  .v-overlay--active:before
    opacity: .95
</style>