<template>
  <full-width-section class="make-films-section__container white--text">
    <div class="make-films-section__overlay" />
    <view-wrapper no-header>
      <div class="make-films-section mt-0">
        <h2 class="mb-3 white--text">Make short films from this documentary</h2>
        <p>Use the video footage from this documentary to create a series of short films. Then share your films with others on social media to broaden the conversation about wildfires.</p>
        <v-btn color="accent" depressed class="btn-extra-wide ml-0" :to="{ name: 'make' }">MAKE SHORT FILMS</v-btn>
      </div>
    </view-wrapper>
  </full-width-section>
</template>

<script>
import FullWidthSection from '@/components/FullWidthSection/FullWidthSection'

export default {
  name: 'MakeFilmsSection',

  components: {
    FullWidthSection
  }
}
</script>

<style lang="stylus">
  .make-films-section__container
    background: url('/img/make-films-bg-hi-res.jpg')
    background-position: center center
    background-size: cover

  .make-films-section
    padding: 75px 0

    @media(min-width:$sm-breakpoint)
      padding: 100px 0
    @media(min-width:$md-breakpoint)
      padding: 150px 0

  .make-films-section__overlay
    pointer-events: none
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    background-color: rgba($color-dark-grey, 0.7)
</style>