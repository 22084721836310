<template>
  <div class="featured-video">
    <youtube-video
      video-id="vdl5DUahp_s"
      :mute="!volume"
      background
      overlay
      :controls="false"
      :crop="100"
      poster
      poster-src="/img/mainPoster.jpg"
      v-bind:syncedPlayer.sync="player"
      @ready="handleReady"
      @ended="handleEnded"
      @playing="handlePlaying"
      ref="youtube">
    </youtube-video>

    <!-- <v-btn color="accent" icon @click="volume = !volume" class="featured-video__volume">
      <v-icon>{{ (volume) ? 'volume_up' : 'volume_off' }}</v-icon>
    </v-btn> -->

    <transition name="slow-slide" mode="out-in" slot="overlay">
      <h1 class="feature-video__header" v-if="headerTextReady">
        How have wildfires impacted you?
      </h1>
    </transition>

    <transition name="fade-slow" mode="out-in">
      <v-progress-circular
        v-if="!videoReady"
        style="position: absolute; top: 15px; left: 15px; opacity: 0.5"
        :size="50"
        color="white"
        class="hidden-sm-and-down"
        indeterminate>
      </v-progress-circular>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'featured-video',

    mounted() {
      if (this.$vuetify.breakpoint.smAndDown) {
        setTimeout(() => {
          this.headerTextReady = true
        }, 1000)
      }
    },

    data() {
      return {
        player: null,
        playing: false,
        videoReady: false,
        headerTextReady: false,
        volume: false,
        videoSupport: false
      }
    },

    methods: {
      handleReady() {
        this.videoReady = true
        setTimeout(() => {
          if (!this.playing) {
            this.headerTextReady = true
          }
        }, 2000)
      },
      handlePlaying() {
        this.playing = true
        this.$emit('playing')

        setTimeout(() => {
          this.headerTextReady = true
        }, 3000)
      },
      handleEnded() {
        this.$emit('ended')
      }
    }
  }
</script>

<style lang="stylus">
  .featured-video
    position: relative
    overflow: hidden

    margin: -25px -25px 50px
    max-height: 80vh

    @media(max-width: $sm-breakpoint)
      margin: -25px -16px 0px

    .feature-video__header
      font-size: 110px
      max-width: 600px
      font-weight: 900
      bottom: -25px
      left: 10%

      @media(max-width: $md-breakpoint)
        left: 0
        // bottom: -40px
        max-width: 500px
        font-size: 80px

      @media(max-width: 480px)
        left: 0
        max-width: 300px
        font-size: 48px

    .youtube-video--overlay-bg
      background-color: #000
      opacity: 0.5

  .feature-video__header
    color: $color-off-white
    font-size: 70px
    max-width: 50vw
    position: absolute
    bottom: 0
    line-height: 1
    padding: 20px

  .featured-video__volume
    position: absolute
    right: 15px
    bottom: 15px
    height 36px !important
    border-radius 50% !important

    &:hover
      position: absolute
      right: 15px
      bottom: 15px
</style>