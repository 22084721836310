<template>
  <div :class="getClassNames" v-bind="dataAttributes"></div>
</template>

<script>
  let AddThis = {
    props: {
      pubId: {
        required: true,
        type: String
      },
      dataUrl: String,
      dataTitle: String,
      dataDescription: String,
      dataMedia: String,
      size: {
        type: Number,
        default: 30
      }
    },

    data() {
      return {
        id: 'addThisScript'
      }
    },

    mounted() {
      if (!document.getElementById(this.id)) {
        let addthisScript = document.createElement('script');
        addthisScript.setAttribute('src', `//s7.addthis.com/js/300/addthis_widget.js#pubid=${this.pubId}`)
        addthisScript.setAttribute('id', this.id)
        document.body.appendChild(addthisScript)
      } else {
        window.addthis.layers.refresh()
      }
    },

    computed: {
      getClassNames() {
        // placeholder for when we need to incorporate more styles

        return {
          'addthis_inline_share_toolbox': true
        }
      },
      getStyles() {
        return {

        }
      },
      dataAttributes() {
        return {
          'data-url': this.dataUrl,
          'data-title': this.dataTitle,
          'data-description': this.dataDescription,
          'data-media': this.dataMedia
        }
      }
    }
  }

  export default AddThis
</script>

<style lang="stylus">
  $size = 30px

  .at-style-responsive .at-share-btn
    padding: 0 !important
    margin-right: 15px !important
    border-radius: 0 !important

  .at-icon-wrapper
    width: $size !important
    height: $size !important

    svg
      width: $size !important
      height: $size !important
</style>